@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-dark {
    --color-primary-btn: linear-gradient(#57FE76, #1EBAC4);
    --color-primary-bg: 19 19 28;
    --color-on-primary-bg: 30 30 41;
    --color-on-primary-bg-soft: 30 30 41;
    --color-on-primary-bg-softer: #656578;
    --color-primary-text: 255 255 255;
    --color-on-hover-primary-text: #6E6E89;

    --color-secondary: #FFFF; 
    --color-secondary-bg: #132125;
    --color-on-secondary: #FFFF;

    --color-accent: #24383E;
    --color-nano-green: 87 254 118;
    --color-error: 239 83 80;
}

.theme-light {
    --color-primary-bg: #FFFFFF;
    --color-primary-text: var(--color-primary-100);
    --color-secondary-text: var(--color-primary-100);
    --color-primary-background: var(--color-primary-100);
    --color-secondary-background: var(--color-primary-100);
}

.container {
    max-width: 1260px !important;
}

.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E") !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E") !important;
    transform: rotate(-180deg);
}